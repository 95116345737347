import {PewComponent} from "../../../assets/raw/js/components/pew-component";
import {gsap} from "gsap";
const {ScrollTrigger} = require("gsap.ScrollTrigger");
gsap.registerPlugin(ScrollTrigger);

export class ChiffreCleComponent extends PewComponent {

  init() {
    this.registerAnimation();
  }

  registerAnimation() {
    const $target = this.element.find('.chiffre');
    if($target.length) {
      gsap.from($target, {
        textContent: 0,
        duration: .8,
        ease: "Power1.easeIn",
        snap: {textContent: 1},
        stagger: 1,
        scrollTrigger: {
          trigger: $target,
          start: "bottom bottom", // the default values
          // end: "bottom top",
          toggleActions: "restart pause resume reset"
        },
      });
    }
  }
}

window.pew.addRegistryEntry({key: 'chiffres-cles', domSelector: '.item-chiffre-cle', classDef: ChiffreCleComponent});
