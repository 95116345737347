import {PewComponent} from "../../../assets/raw/js/components/pew-component";

class WorksComponent extends PewComponent {
  constructor(element, options) {
    super(element, options);
  }

  init() {
    this.manageTabs();
  }

  manageTabs() {
    this.element.find(".tabs").on("click", ".tab:not(.open)", (e) => {
      let sectionId = $(e.currentTarget).data("section-tab");

      this.element.find(".tabs .tab.open").removeClass("open");
      $(e.currentTarget).addClass("open");

      this.element.find(".section.open").removeClass("open");
      this.element.find(".section[data-section-content='" + sectionId + "']").addClass("open");
    });
  }
}

window.pew.addRegistryEntry({
  key: 'WorksComponent',
  domSelector: '.works-component',
  classDef: WorksComponent
});

class WorkDetailComponent extends PewComponent {
  constructor(element, options) {
    super(element, options);
  }

  init() {
    this.manageTabs();
  }

  manageTabs() {
    this.element.find(".tabs").on("click", ".phase-selector:not(.open)", (e) => {
      let phaseNumber = $(e.currentTarget).data("phase-tab");

      this.element.find(".tabs .phase-selector.open").removeClass("open");
      $(e.currentTarget).addClass("open");

      this.element.find(".phase-content.open").removeClass("open");
      this.element.find(".phase-content[data-phase-content='" + phaseNumber + "']").addClass("open");
    });
  }
}

window.pew.addRegistryEntry({
  key: 'WorkDetailComponent',
  domSelector: '.type-sections',
  classDef: WorkDetailComponent
});
