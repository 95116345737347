import {PewComponent} from "../../../../assets/raw/js/components/pew-component";

class FlippableCardComponent extends PewComponent {
  init(){
    this.element.on('click',(e)=>{
      if("ontouchstart" in document.documentElement) {
        this.element.toggleClass('hover');
      }
    });
  }
}

window.pew.addRegistryEntry({key: 'FlippableCardComponent', domSelector: '.flippable-card', classDef: FlippableCardComponent});
