import {PewComponent} from "../../../assets/raw/js/components/pew-component";

class AccessibilityComponent extends PewComponent {
  constructor(element, options) {
    const referenceBodyFontSize = parseInt($('body').css('font-size').replace('px', ''));

    const defaultOptions = {
      'storageKey': 'a11yWidget',
      'dyslexiaClass': 'font-family-accessibility',
      'lineHeight': 2,
      'fontSizes': {
        min: referenceBodyFontSize - 3,
        max: referenceBodyFontSize + 3,
        reference: referenceBodyFontSize
      }
    };

    options = Object.assign(defaultOptions, options);

    super(element, options);
  }

  init() {
    this.state = this.getState();
    this.restoreState();
    this.registerAccessibilityToggler();
    this.registerDyslexiaToggler();
    this.registerLineHeightToggler();
    this.registerFontSizer();
  }

  restoreState() {
    //console.log(this.state);
    this.setFontSize(this.state.fontSize, false);
    this.restoreDyslexia();
    this.restoreLineHeight();
  }

  getState() {
    const defaultState = {
      dyslexia: false,
      lineHeight: false,
      fontSize: this.options.fontSizes.reference
    };
    if (!window.localStorage) {
      return defaultState;
    }
    const state = window.localStorage.getItem(this.options.storageKey);

    return typeof state === 'string' ? JSON.parse(state) : defaultState;
  }

  setState(newState) {
    this.state = Object.assign(this.state, newState);
    if (window.localStorage) {
      window.localStorage.setItem(this.options.storageKey, JSON.stringify(this.state));
    }
    //console.log('setting new state', newState, this.state);
  }

  registerAccessibilityToggler() {
    this.element.find('#accessibility-toggler').on('click', () => {
      this.element.find('.part-2').slideToggle();
    });
  }

  registerDyslexiaToggler() {
    this.element.find('#accessibility-alt-font').on('change', (e) => {
      const $cb = $(e.currentTarget);
      this.setDyslexia($cb.is(':checked'), true);
    });
  }

  restoreDyslexia() {
    this.setDyslexia(this.state.dyslexia, false);
    if (this.state.dyslexia) {
      this.element.find('#accessibility-alt-font').prop('checked', true);
    }
  }

  setDyslexia(shouldHaveDyslexia, shouldSave) {
    if (shouldHaveDyslexia) {
      $('body').addClass(this.options.dyslexiaClass);
    } else {
      $('body').removeClass(this.options.dyslexiaClass);
    }
    if (shouldSave) {
      this.setState({dyslexia: shouldHaveDyslexia});
    }
  }

  registerLineHeightToggler() {
    this.element.find('#accessibility-line-spacing').on('change', (e) => {
      const $cb = $(e.currentTarget);
      this.setLineHeight($cb.is(':checked'), true)
    });
  }

  restoreLineHeight() {
    this.setLineHeight(this.state.lineHeight, false);
    if (this.state.lineHeight) {
      this.element.find('#accessibility-line-spacing').prop('checked', true);
    }
  }

  setLineHeight(shouldHaveLineHeight, shouldSave) {
    if (shouldHaveLineHeight) {
      $('body').css('line-height', this.options['lineHeight']);
    } else {
      $('body').css('line-height', '');
    }
    if (shouldSave) {
      this.setState({lineHeight: shouldHaveLineHeight});
    }
  }

  registerFontSizer() {
    this.element.find('#accessibility-font-smaller').on('click', () => {
      this.setFontSize(this.getPreviousSize(), true);
    });
    this.element.find('#accessibility-font-bigger').on('click', () => {
      this.setFontSize(this.getNextSize(), true);
    });
  }

  setFontSize(size, shouldSave) {
    $('body').css('font-size', size + 'px');
    if (shouldSave) {
      this.setState({fontSize: size});
    }
  }

  getPreviousSize() {
    let previousSize = this.state.fontSize - 1;
    if (previousSize < this.options.fontSizes.min) {
      previousSize = this.options.fontSizes.min;
    }
    return previousSize;
  }

  getNextSize() {
    let nextSize = this.state.fontSize + 1;
    if (nextSize > this.options.fontSizes.max) {
      nextSize = this.options.fontSizes.max;
    }
    return nextSize;
  }
}

window.pew.addRegistryEntry({
  key: 'AccessibilityComponent',
  domSelector: '.accessibility-component',
  classDef: AccessibilityComponent
});
